import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const InvitationQRCode = ({ value, size = 256, bgColor = "#ffffff", fgColor = "#000000" }) => {
    return (
        <div>
            <QRCodeSVG
                value={value}
                size={size}
                bgColor={bgColor}
                fgColor={fgColor}
            />
        </div>
    );
};

export default InvitationQRCode;
