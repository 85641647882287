// PasswordReset.js
import React, { useState } from 'react';
import { sendPasswordResetEmail} from 'firebase/auth';
import { auth } from './firebase';
import './index.css';

function PasswordReset() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleReset = () => {
       sendPasswordResetEmail(auth, email)
            .then(() => {
                setMessage('Password reset email sent!');
                setError(null);
                setShowModal(false);
            })
            .catch((error) => {
                setError(error.message);
                setMessage(null);
            });
    };

    return (
        <div>
            {/*<h2>Password Reset</h2>*/}
            <br/> <br/>
            <button className="btn-reset" onClick={() => setShowModal(true)}>Reset Password</button>
            <br/><br/>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        {error && <p>{error}</p>}
                        {message && <p>{message}</p>}
                        <input
                            type="email"
                            placeholder="Email"
                            className="search"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className="btn-reset" onClick={handleReset}>Reset Password</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PasswordReset;
