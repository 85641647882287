import React, {useState, useEffect} from 'react';
import whereh from './images/WhereH.png';
import Sketch from './sketch';
import styles from './index.css';
import Humanoids from "./sketch2";

const Intro = () => {
    return (
        <>
                <Humanoids/>
                {/*<Sketch/>*/}
                {/*<Sketch/>*/}
                {/*<Sketch/>*/}
                {/*<Sketch/>*/}
                {/*<Sketch/>*/}
                {/*<Sketch/>*/}
                {/*<Sketch/>*/}
                {/*<center><img src={whereh} className="splash"/></center>*/}
        </>

    );
}

export default Intro;