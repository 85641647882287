// Accept invitation
import React, {useEffect, useState} from 'react';

function AcceptInvitation(props) {
    return (
        <div className="download-container">
            <br/>
            {/*{!props.notnewuser && <h2 className={"multi-color-heading"}>New users please download our app and sign up</h2> }*/}
            {!props.notnewuser && <h2 className={"multi-color-heading"}>Currently the mobile app is available by invite only. It will be generally available soon!!</h2> }
            {props.notnewuser && <h2 className={"multi-color-heading"}>Next download our app and get involved in the community</h2> }
            <br/><br/><br/><br/>
            <p>Get our app on:</p><br/>
            <div className="download-buttons">
            <a
                href="https://apps.apple.com/us/app/safari/id1146562112"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                    alt="Download on the App Store"
                    height="55"
                />
            </a>
                <div>
            <a
                href="https://play.google.com/store/apps/details?id=com.yourappname"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    alt="Get it on Google Play"
                    height="80"
                />
            </a>
                </div>
            </div>
        </div>
    );

}

export default AcceptInvitation;