import { useState, useEffect } from "react";
import {app, database} from "./firebase";
import {equalTo, get, orderByChild, query, ref} from "firebase/database";

export function useMemberships(email) {
    const [memberships, setMemberships] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const queryRef = ref(database, 'membership');

    // const membershipQuery = query(queryRef, orderByChild('email')); // Temp
    const membershipQuery = query(queryRef, orderByChild('email'), equalTo(email));
    // TODO: Issues with equalto case sensitive for email Jane@example.com and jane@example.com
    // option: startAt(text.toUppercase) and endAt(text.toLowerCase+ "\uf8ff")
    useEffect(
        function () {
            const controller = new AbortController();

            async function fetchMemberships() {
                try {

                    console.log(" chatQuery", membershipQuery);
                    let data = '';

                    await get(membershipQuery)
                        .then((snapshot) => {
                            data = snapshot.toJSON();
                            console.log('snapshot', snapshot.toJSON());
                            return data;
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                        });

                    // const data = {
                    //     "-NwiX5AKzWvZkfbdq_PX": {
                    //         "communityName": "new Name",
                    //         "email": "joe@example.com",
                    //         "communityId": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r1"
                    //     },
                    //     "-NwjOOdb8NonWMMfzWcp": {
                    //         "communityName": "All the Els",
                    //         "email": "joe@example.com",
                    //         "communityId": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r2"
                    //     },
                    //     "-NwjOpn4y3-HTBwtoqo9": {
                    //         "communityName": "ask Jill",
                    //         "email": "joe@example.com",
                    //         "communityId": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r3"
                    //     },
                    //     "-NwjP7bYBNtvkN6e9jFZ": {
                    //         "communityName": "ask Jill",
                    //         "email": "joe@example.com",
                    //         "communityId": "lZ8Og8uzxMTpfBiZcdAGfr2ff1r4"
                    //     },
                    //     "-NwqbhNYUVxLVHucnKXt": {
                    //         "communityName": "No Joe",
                    //         "email": "joe@example.com",
                    //         "communityId": "Q5LltgOUlha0tnsirjjR5swegki5"
                    //     }
                    // };

                    let finalData = [];

                    for(const key of Object.keys(data)){
                        console.log('Is teh key' + key);
                        // console.log('group' + data[key].isGroupChat);
                        // NOT NECESSARY TO CHECK

                        // if(data[key].email === email)
                            finalData.push({
                                "id": key,
                                "communityId": data[key].communityId,
                                "name": data[key]?.communityName});
                    }

                    console.log('finalData', finalData);

                    if (data.Response === "False") throw new Error("Membership not found");

                    setMemberships(finalData);
                    setError("");
                } catch (err) {
                    if (err.name !== "AbortError") {
                        console.log(err.message);
                        setError(err.message);
                    }
                } finally {
                    setIsLoading(false);
                }
            }

            fetchMemberships();

            return function () {
                controller.abort();
            };
        },
        [email]
    );

    return { memberships, setMemberships, isLoading, error };
}
