import React, { useState } from 'react';
import InvitationQRCode from './invitationQRCode';
import { useLocation } from 'react-router-dom';
import AcceptInvitation from "./acceptInvitation";

function PublicInvite() {
    // const location = useLocation();
    const query = new URLSearchParams(useLocation().search);
    // const { cid, cname } = location.state || {};

    const cid = query.get('cid');
    const cname = query.get('cname');

    const QRCodeUrl = `https://askjillai.com/login?form=invitations&&publicinvite=true&&cid=${cid}&cname=${cname}`

    return (
        <>
            <div className="download-container">
                <br/><br/>
                <h2 className={"multi-color-heading"}>
                    Welcome to {cname}.
                </h2>
                <br/>
                <h2 className={"multi-color-heading"}>
                  To join scan the QR Code to get started.
                </h2>
                <br/>
                <InvitationQRCode value={QRCodeUrl} />
            </div>
            <br/><br/><br/>
            <div>
                <AcceptInvitation notnewuser={true} />
            </div>
        </>

    );
}

export default PublicInvite;
