import React, {useEffect, useMemo, useState} from "react";
import {useMemberships} from "./useMemberships";
import {app, auth, database} from "./firebase";
import {remove, ref, update, push, get} from "firebase/database";
import {onAuthStateChanged} from "firebase/auth";

function InvitationsList(props) {
    const [userId, setUserId] = useState('');
    const {memberships, setMemberships, isLoading, error} = useMemberships(props.email)
    const [decline, setDecline] = useState('');
    const [accept, setAccept] = useState('');

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setUserId(uid);
                console.log("uid", uid)
            } else {
                setUserId(null);
            }
        });

    }, []);

    return (

        <>
            {accept && <AcceptMessage message={accept}/>}
            {decline && <DeclineMessage message={decline}/>}
            {/*<ul className="list">*/}
            {memberships?.map((membership) => (
                <Invitation currentUserEmail={props.email} recordId={membership.id} communityName={membership.name} communityId={membership.communityId}
                            onAccept={handleAccept} onDecline={handleDecline}/>
            ))}
            {/*</ul>*/}
        </>
    )

    async function handleAccept(recordId, communityId, communityName) {

        const recordRef = ref(database, `chats/${communityId}`);
        const userChatRef = ref(database, `userChats/${userId}`);

        // Fetch the current data from the database
        await get(recordRef)
            .then(async (snapshot) => {
                const currentData = snapshot.val();
                // Check if the record exists
                if (currentData) {
                    // Update the record by adding the additional value to the array
                    const newData = {
                        ...currentData,
                        users: [...currentData.users, userId]
                    };
                    // Update the record in the database
                    await update(recordRef, newData)
                        .then(() => {
                            console.log('Record updated successfully');
                        })
                        .catch((error) => {
                            console.error('Error updating record:', error);
                        });
                    // Add user to the community chat.
                    await push(userChatRef, communityId)
                        .then(() => {
                            console.log('User CHat Record updated successfully');
                        })
                        .catch((error) => {
                            console.error('Error updating record:', error);
                        });
                } else {
                    console.log('Record not found');
                }
            })
            .catch((error) => {
                console.error('Error fetching record:', error);
            });

        // Under User Id a new record with the community id / chat group id

        await removeFromMembership(recordId,communityName);

        setMemberships(memberships.filter(member => member.id != recordId));

        // delete from the membership

        setAccept(`Invite from ${communityName} accepted`);
        console.log(communityName + communityId);
    }

    function DeclineMessage({message}) {
        return (
            <p className="error">
                <span>⛔️</span> {message}
            </p>
        );
    }

    function AcceptMessage({message}) {
        return (
            <p className="success">
                <span>✅️</span> {message}
            </p>
        );
    }

    async function removeFromMembership(recordId, communityName){
        await remove(ref(database, 'membership/' + recordId))
            .then(() => {
                console.log('Record deleted successfully');

            })
            .catch((error) => {
                console.error('Error deleting record:', error);
            });
    }

    async function handleDecline(recordId, communityId, communityName) {

        await removeFromMembership(recordId, communityName);

        // await remove(ref(database, 'membership/' + recordId))
        //     .then(() => {
        //         setDecline(`Invite from ${communityName} declined`);
        //         console.log('Record deleted successfully');
        //
        //     })
        //     .catch((error) => {
        //         console.error('Error deleting record:', error);
        //     });

        setMemberships(memberships.filter(member => member.id != recordId));
        setDecline(`Invite from ${communityName} declined`);


        console.log(communityName);
    }

    function Invitation({
                            currentUserEmail, recordId, communityName, communityId, onAccept,
                            onDecline
                        }) {

        return (
            <div className="box">
                <div>
                    <label>Community Name:</label> &nbsp;
                    <label>{communityName}</label>
                </div>
                <br/>
                <button className="btn-add" onClick={() => onAccept(recordId, communityId, communityName)}>
                    Accept
                </button>
                <button className="btn-delete" onClick={() => onDecline(recordId, communityId, communityName)}>
                    Decline
                </button>
                <br/><br/>
            </div>
        )

    }
}


function Invitations(props) {

    return (
        <div>
            <p>
                Invitations to join the communities
            </p>
            <br/><br/>
            <InvitationsList email={props.email}/>
        </div>
    );

}

export default Invitations;