// src/services/EmailService.js
import emailjs from 'emailjs-com';

class EmailService {
    constructor() {
        this.serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        this.templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        this.userId = process.env.REACT_APP_EMAILJS_USER_ID;
        this.fromName = process.env.REACT_APP_EMAILJS_FROM_Name;
        this.fromEmail = process.env.REACT_APP_EMAILJS_FROM_EMAIL;
    }

    async sendEmail(toEmails, message) {
        console.log(' Email service activated ');
        const sendEmailPromises = toEmails.map(toEmail => {
            const templateParams = {
                to_email: toEmail,
                from_name: "ask Jill AI",
                from_email: this.fromEmail,
                message: message,
            };

            console.log(' template params ', templateParams);

            return emailjs.send(this.serviceId, this.templateId, templateParams, this.userId);
        });

        return Promise.all(sendEmailPromises)
            .then(responses => {
                console.log('SUCCESS!', responses);
                return responses;
            })
            .catch(err => {
                console.error('FAILED...', err);
                throw err;
            });
    }
}

export default EmailService;
