import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { FirstPersonControls } from '@react-three/drei';
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import './main.css';
// import water from './images/water.jpg';
import styles from './index.css';
// Extend will make the FirstPersonControls component available as a JSX element
extend({ FirstPersonControls });

const DynamicGeometry = ({ statsRef }) => {
    const meshRef = useRef();
    const controlsRef = useRef();
    const clock = new THREE.Clock();
    const stats = useRef();
    const worldWidth = 128;
    const worldDepth = 128;

    useEffect(() => {
        // Set up stats
        stats.current = new Stats();
        if (statsRef.current) {
            statsRef.current.appendChild(stats.current.dom);
        }

        // Cleanup on unmount
        return () => {
            if (statsRef.current) {
                statsRef.current.removeChild(stats.current.dom);
            }
        };
    }, [statsRef]);

    // Initialize geometry
    const geometry = new THREE.PlaneGeometry(20000, 20000, worldWidth - 1, worldDepth - 1);
    geometry.rotateX(-Math.PI / 2);

    const position = geometry.attributes.position;
    position.usage = THREE.DynamicDrawUsage;

    for (let i = 0; i < position.count; i++) {
        const y = 35 * Math.sin(i / 2);
        position.setY(i, y);
    }

    // Load texture
    const texture = new THREE.TextureLoader().load(require('./images/water.jpg'));
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(5, 5);

    // Animation loop
    useFrame(() => {
        const delta = clock.getDelta();
        const time = clock.getElapsedTime() * 10;

        for (let i = 0; i < position.count; i++) {
            const y = 35 * Math.sin(i / 5 + (time + i) / 7);
            position.setY(i, y);
        }

        position.needsUpdate = true;

        controlsRef.current.update(delta);
        if (stats.current) stats.current.update();
    });

    return (
        <>
            <mesh ref={meshRef} geometry={geometry}>
                <meshBasicMaterial attach="material" color={0x0044ff} map={texture} />
            </mesh>
            <FirstPersonControls ref={controlsRef} movementSpeed={500} lookSpeed={0.1} />
        </>
    );
};

const Sketch = () => {
    const statsRef = useRef(null);
    const [heightWidth, setHeightWidth] = useState(1000);
    return (
        <>
            {/*<div ref={statsRef}></div>*/}
            <div className={styles.fullpage}>
                <Canvas height={heightWidth} camera={{ fov: 60, position: [0, 200, 0] }}>
                    <ambientLight />
                    <DynamicGeometry statsRef={statsRef} />
                </Canvas>
            </div>

        </>
    );
};

export default Sketch;
