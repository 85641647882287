import React, {useState} from 'react';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth, database} from './firebase';
import {NavLink, useNavigate} from 'react-router-dom'
import PasswordReset from "./passwordReset";
import AcceptInvitation from "./acceptInvitation";
import { useLocation } from 'react-router-dom';
import SignUp from "./signup";
import {child, push, ref, set} from "firebase/database";
// import './index.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Login = () => {
    let query = useQuery();

    const publicinvite = query.get("publicinvite");
    const communityid = query.get("cid");
    const communityname = query.get("cname");
    const form = query.get("form");
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const createOnlyMembership = async (userId) => {

        const mbrRef = ref(database, 'membership');

        await push(mbrRef, {
            email: email.trim(),
            communityId: communityid,
            communityName: communityname
        }).then(() => {
            // setMessage('Emails added successfully');
            console.log(' MEMBER SHIP CRET ', email.trim())
        }).catch((error) => {
            console.log(' Error creating message ', error);
            // setMessage(`Failed to add emails: ${error.message}`);

        });

    }

    const onLogin =  (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in
                const user = userCredential.user;
                const uid = user.uid;
                console.log(' U found' + uid);
                console.log(' publicinvite ', publicinvite + communityid);
                if(publicinvite == "true" && communityid != undefined){
                    console.log('Creating membership');
                    await createOnlyMembership(uid);
                }
                navigate(`/home?form=${form}`);
                console.log('This is the user' + user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    return (
        <>
            <main className="container">
                <div className="block1">
                <section>
                    <div className="box">
                        <p> Existing users please login with your <b>askJill</b> account </p>

                        <form>
                            <div>
                                <label htmlFor="email-address">
                                    Email address
                                </label>
                                <input
                                    className="search"
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor="password">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    className="search"
                                    name="password"
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <br/>
                                <button
                                    className="btn-add"
                                    onClick={onLogin}
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                        <PasswordReset/>

                    </div>
                </section>
                </div>
                <div className="block2">
                    {
                        communityid && publicinvite &&
                        <SignUp cid={communityid} cname={communityname} form={form}/>
                    }
                    {
                        !communityid && <AcceptInvitation/>
                    }
                </div>
            </main>
        </>
    )
}

export default Login