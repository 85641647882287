// Accept invitation
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {auth, database} from "./firebase";
import {child, remove, ref, update, push, get, set} from "firebase/database";

//public invite format
//http://localhost:3000/login?form=invitations&&publicinvite=true&&cid=NwjOOdb8NonWMMfzWcp&cname=All%20the%20Els

function SignUp(props) {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const cid = props.cid;
    const cname = props.cname;
    const form = props.form;

    const createUserAndMembership = async (userId) => {
        const firstLast = `${firstName} ${lastName}`.toLowerCase();
        const displayName = `${firstName} ${lastName}`;
        const userData = {
            firstName,
            lastName,
            firstLast,
            displayName,
            email,
            userId,
            signUpDate: new Date().toISOString()
        };

        const dbRef = ref(database);
        const childRef = child(dbRef, `users/${userId}`);
        const mbrRef = ref(database, 'membership');
        await set(childRef, userData);

        await push(mbrRef, {
            email: email.trim(),
            communityId: cid,
            communityName: cname
        }).then(() => {
            // setMessage('Emails added successfully');
            console.log(' MEMBER SHIP CRET ', email.trim())
        }).catch((error) => {
            console.log(' Error creating message ', error);
            // setMessage(`Failed to add emails: ${error.message}`);

        });

        return userData;
    }

    const onSignup = async (e) => {
        e.preventDefault();
        try{
            const result = await createUserWithEmailAndPassword(auth, email, password);

            const userForVerification = result.user;
            await sendEmailVerification(userForVerification);

            const { uid, stsTokenManager } = result.user;

            const userData = await createUserAndMembership(uid);
            navigate(`/home?form=${form}`);

        }
        catch(error){
            console.log(error);
        }

    }

    return (
        <div className="block1">
            <section>
                <div className="box">
                    <p> New users please sign up join the community {cname}</p>

                    <form>
                        <div>
                            <label htmlFor="First Name">
                                First Name
                            </label>
                            <input
                                className="search"
                                id="first-name"
                                name="firstname"
                                type="text"
                                required
                                placeholder="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="Last Name">
                                Last Name
                            </label>
                            <input
                                className="search"
                                id="last-name"
                                name="lastname"
                                type="text"
                                required
                                placeholder="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="email-address">
                                Email address
                            </label>
                            <input
                                className="search"
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                placeholder="Email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div>
                            <label htmlFor="password">
                                Password
                            </label>
                            <input
                                id="password"
                                className="search"
                                name="password"
                                type="password"
                                required
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div>
                            <br/>
                            <button
                                className="btn-add"
                                onClick={onSignup}
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>

                </div>
            </section>
        </div>
    );

}

export default SignUp;