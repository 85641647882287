// PasswordReset.js
import React, {useState} from 'react';
import {sendPasswordResetEmail} from 'firebase/auth';
import {auth} from './firebase';
import './index.css';

function PasswordReset() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const handleReset = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setMessage('Password reset email sent!');
                setError(null);
            })
            .catch((error) => {
                setError(error.message);
                setMessage(null);
            });
    };

    return (
        <>

            <div className="adj">
                <h2>Enter your email address to get a password reset link</h2>
                <br/><br/><br/>
                {error && <p>{error}</p>}
                {message && <p>{message}</p>}
                <input
                    type="email"
                    className="search"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btn-reset" onClick={handleReset}>Reset Password</button>
            </div>

        </>
    );
}

export default PasswordReset;
